export const pastEventsData = [
  {
    photoSrc: [
      "https://strawcraft.s3.eu-west-2.amazonaws.com/artisan-market.jpg",
    ],
    title: "Artisan Market",
    date: "Sunday 2nd February 10-3pm",
    subtitle:
      "Come and see us here! New kits will be available to purchase - plus lots of Imbolc St Brigid's Crosses and Valentine gifts.",
  },
  {
    photoSrc: [
      "https://strawcraft.s3.eu-west-2.amazonaws.com/larkswold/0e3b1b_667e2fc34cd74e31a00c8b3f922f29d9~mv2.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/larkswold/0e3b1b_010b0ab5e39e48cf944bca1bdbbb325c~mv2.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/larkswold/0e3b1b_cf35bd4c4e2b4e40bc64d7473a55af88~mv2.webp",
    ],
    title: "Strawcraft Workshop, Flat Plaits",
    date: "Tuesday 18th February 10-1pm",
    subtitle:
      'Flat Plaits workshop at <b>Larkswold Creative Workshops, Adlestrop</b>, near Moreton in Marsh, Glos. <a target="_blank" rel="noopener noreferrer" href="https://www.larkswold.com/service-page/strawcraft-workshop-flat-plaits-1?referral=service_list_widget">Book here.</a>',
  },
  {
    photoSrc: [
      "https://strawcraft.s3.eu-west-2.amazonaws.com/Straw-flat-plait-workshop-flyer-draft-_1_.jpg",
    ],
    title: "Corn Dolly Flat Plait Workshop",
    date: "23rd March 2025",
    subtitle:
      'Upcoming workshop at The Fold, Bransford, Worcester. <a target="_blank" rel="noopener noreferrer" href="https://www.eventbrite.co.uk/e/corn-dolly-flat-plait-workshop-tickets-1208335978439">Book here.</a>',
    border: true,
  },
  {
    photoSrc: [
      "https://strawcraft.s3.eu-west-2.amazonaws.com/homeworkshop/IMG_2537.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/homeworkshop/IMG_2659.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/homeworkshop/IMG_2664.webp",
    ],
    title: "Christmas Straw Angel Workshop",
    date: "Autumn 2024",
    subtitle:
      "This workshop demonstrated two different straw plaits and then combined with 'tied straw work' to produce a straw angel for the christmas tree.",
  },
  {
    photoSrc: [
      "https://strawcraft.s3.eu-west-2.amazonaws.com/guides/IMG_0004.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/guides/IMG_2543.webp",
    ],
    title: "Enstone Guides",
    date: "Autumn 2024",
    subtitle:
      "A workshop with The Enstone Guides. During the session pupils produced 'compass plait' and 'hair-plait' favours.",
  },
  {
    photoSrc: [
      "https://strawcraft.s3.eu-west-2.amazonaws.com/farmed2/IMG_2535.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/farmed2/IMG_2547.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/farmed2/IMG_2534.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/farmed2/IMG_2536.webp",
    ],
    title: "FarmED - Harvest Festival",
    date: "Autumn 2024",
    subtitle:
      "A workshop with FarmED, celebrating Harvest Festival on their 107 acre demonstration farm in the Cotswolds. Over 200 harvest favours were produced during the day. [Find out more here.](https://www.farm-ed.co.uk/news/43/harvest-festival)",
  },
  {
    photoSrc: [
      "https://strawcraft.s3.eu-west-2.amazonaws.com/workshop3.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/workshop1.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/workshop4.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/workshop2.webp",
    ],
    title: "'As I was A-Walking'",
    date: "Summer 2024",
    subtitle:
      "A workshop in conjunction with Chipping Norton Theatre and FarmED.",
  },
];
