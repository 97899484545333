import React, { useEffect, useState } from "react";
import styles from "./PastEvents.module.css";
import { pastEventsData } from "./PastEvents.entity";
import leftArrow from "../Icons/left.svg";
import rightArrow from "../Icons/right.svg";

function PastEvents() {
  const [mouseOutKey, setMouseOutKey] = useState(0);

  const [activeImageIndices, setActiveImageIndices] = useState(
    pastEventsData.map(() => 0)
  );

  const [projectArrowVisibility, setProjectArrowVisibility] = useState(
    pastEventsData.map(() => false)
  );

  const [touchStartX, setTouchStartX] = useState(null);
  const [touchEndX, setTouchEndX] = useState(null);
  const [touchStartY, setTouchStartY] = useState(null);
  const [touchEndY, setTouchEndY] = useState(null);

  const handleTouchStart = (e, projectIndex) => {
    if (e.touches && e.touches.length > 0) {
      setTouchStartX(e.touches[0].clientX);
      setTouchStartY(e.touches[0].clientY);
      showArrows(projectIndex);
    }
  };

  const handleTouchMove = (e) => {
    setTouchEndX(e.touches[0].clientX);
    setTouchEndY(e.touches[0].clientY);
  };

  const handleTouchEnd = (projectIndex) => {
    if (
      touchStartX !== null &&
      touchEndX !== null &&
      touchStartY !== null &&
      touchEndY !== null
    ) {
      const deltaX = touchEndX - touchStartX;
      const deltaY = touchEndY - touchStartY;
      console.log("dy", deltaY);
      if (deltaX > 50 && deltaY < 50 && deltaY > -50) {
        handlePrevImage(projectIndex);
      } else if (deltaX < -50 && deltaY < 50 && deltaY > -50) {
        handleNextImage(projectIndex);
      }
    }
    setTouchStartX(null);
    setTouchEndX(null);
    setTouchStartY(null);
    setTouchEndY(null);
  };

  const showArrows = (projectIndex) => {
    setProjectArrowVisibility((prevVisibility) => {
      const newVisibility = [...prevVisibility];
      newVisibility[projectIndex] = true;
      return newVisibility;
    });
  };

  const hideArrows = (projectIndex) => {
    setProjectArrowVisibility((prevVisibility) => {
      const newVisibility = [...prevVisibility];
      newVisibility[projectIndex] = false;
      return newVisibility;
    });
  };

  useEffect(() => {
    const arrowTimers = pastEventsData.map((_, projectIndex) => {
      return setTimeout(() => hideArrows(projectIndex), 2000);
    });
    return () => {
      arrowTimers.forEach((timer) => clearTimeout(timer));
    };
  }, [activeImageIndices, projectArrowVisibility, touchEndX, mouseOutKey]);

  const handleNextImage = (projectIndex) => {
    setActiveImageIndices((prevIndices) => {
      const newIndices = [...prevIndices];
      newIndices[projectIndex] =
        (newIndices[projectIndex] + 1) %
        pastEventsData[projectIndex].photoSrc.length;
      return newIndices;
    });
    showArrows(projectIndex);
  };

  const handlePrevImage = (projectIndex) => {
    setActiveImageIndices((prevIndices) => {
      const newIndices = [...prevIndices];
      newIndices[projectIndex] =
        (newIndices[projectIndex] -
          1 +
          pastEventsData[projectIndex].photoSrc.length) %
        pastEventsData[projectIndex].photoSrc.length;
      return newIndices;
    });
    showArrows(projectIndex);
  };

  const handleMouseOver = (projectIndex) => {
    showArrows(projectIndex);
  };

  const handleMouseOut = (projectIndex) => {
    hideArrows(projectIndex);
    setMouseOutKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    const preloadImages = () => {
      pastEventsData.forEach((project) => {
        project.photoSrc.forEach((photo) => {
          const img = new Image();
          img.src = photo;
        });
      });
    };

    preloadImages();
  }, []);

  const parseSubtitle = (text) => {
    const parts = text.split(/(\[.*?\]\(.*?\))/g); // Split text into parts
    return parts.map((part, index) => {
      const match = part.match(/\[(.*?)\]\((.*?)\)/); // Match [text](url) syntax
      if (match) {
        return (
          <a
            key={index}
            href={match[2]}
            target="_blank"
            rel="noopener noreferrer"
          >
            {match[1]}
          </a>
        );
      }
      return part; // Return plain text for non-link parts
    });
  };

  return (
    <div className={styles.content}>
      {pastEventsData.map((project, projectIndex) => (
        <div
          key={projectIndex}
          onTouchStart={(e) => handleTouchStart(e, projectIndex)}
        >
          <div className={styles.subtitle}>{project.title}</div>
          <div className={styles.date}>{project.date}</div>
          <div
            className={styles.tag}
            dangerouslySetInnerHTML={{ __html: project.subtitle }}
          />
          <div className={styles.imageContainer}>
            <div onClick={() => handlePrevImage(projectIndex)}>
              <img
                src={leftArrow}
                alt="Previous"
                className={`${styles.leftArrow} ${
                  projectArrowVisibility[projectIndex] &&
                  project.photoSrc.length > 1
                    ? styles["fade-in"]
                    : styles["fade-out"]
                }`}
              />
            </div>
            <img
              src={project.photoSrc[activeImageIndices[projectIndex]]}
              alt={`${project.title} ${activeImageIndices[projectIndex] + 1}`}
              className={`${styles.image} ${
                project.border ? styles.border : ""
              }`}
              onClick={() => {
                handleNextImage(projectIndex);
              }}
              onTouchStart={(e) => handleTouchStart(e, projectIndex)}
              onTouchMove={handleTouchMove}
              onTouchEnd={() => handleTouchEnd(projectIndex)}
              onMouseOver={() => handleMouseOver(projectIndex)}
              onMouseOut={() => handleMouseOut(projectIndex)}
            />

            <img
              src={rightArrow}
              alt="Next"
              className={`${styles.rightArrow} ${
                projectArrowVisibility[projectIndex] &&
                project.photoSrc.length > 1
                  ? styles["fade-in"]
                  : styles["fade-out"]
              }`}
              onClick={() => handleNextImage(projectIndex)}
            />

            <div className={styles.photoCarousel}>
              {activeImageIndices[projectIndex] + 1}
              {" of "}
              {project.photoSrc.length}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default PastEvents;
